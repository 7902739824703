define(['app', 'siteObj'], function(app, siteObj) {
  
  var _returnsCheck = {

    _data: {
      returnsAndRefundsId: 61,
      itemNoLongerRequiredId: 2,
      orderNumber: null,
      productIds: [],
    },

    _cssClasses: {
      showReturnReasons: 'myAccountSection_returnsCheckOptionsBlock-show',
      showLoading: 'myAccount_returnsCheck_loading-show',
      showButton: 'myAccount_returnsCheck_button-show',
      showCheckError: 'myAccount_returnsCheckError-show',
      showFormError: 'downloadReturnsForm_error-show',
      checkedProductCheckbox: 'js-returns-check-checkbox-checked',
    },

    _elements: {
      // This class is to easily use the app helper to check for a class rather than attr.
      checkedProductCheckbox: '.js-returns-check-checkbox-checked',

      customerConfirmCheckbox: '[data-js-element="myAccountReturnsCheck_customerConfirmation"]',
      checkErrorMessage: '[data-js-element="myAccountReturnsCheck_checkError"]',
      formErrorMessage: '[data-js-element="myAccountReturnsCheck_formError"]',
      returnOptions: '[data-js-element="myAccountReturnsCheck_options"]',
      returnsCheckButton: '[data-js-element="myAccountReturnsCheck_openModal"]',
      returnsFormButton: '[data-js-element="myAccountReturnsCheck_download"]'
    },

    _checkErrorIsVisible: false,
    _formErrorIsVisible: false,

    _loading: document.querySelector('[data-js-element="myAccountReturnsCheck_loading"]'),
    _customerConfirmationCheckbox: document.querySelector('[data-js-element="myAccountReturnsCheck_customerConfirmation"]'),
    _returnOptions: document.querySelector('[data-js-element="myAccountReturnsCheck_options"]'),
    _returnsCheckOptionsBlock: document.querySelector('[data-js-element="myAccountReturnsCheck_optionsBlock"]'),
    _returnsCheck: document.querySelector('[data-component="myAccountReturnsCheck"]'),
    _returnsCheckButton: document.querySelector('[data-js-element="myAccountReturnsCheck_openModal"]'),
    _returnsFormButton: document.querySelector('[data-js-element="myAccountReturnsCheck_download"]'),
    _returnsFormForm: document.querySelector('[data-js-element="myAccountReturnsCheck_form"]'),
    _productCheckbox: document.querySelectorAll('[data-js-element="myAccountReturnsCheck_productCheckbox"]'),

    // This has to stay as a class as I can't add a data attribute into the Spring form tag
    _categoryOptions: document.querySelector('.js-returns-check-categories'),

    _returnsCheckUrl: siteObj.siteSecureURL + 'returnsCheck.account',
    _returnsFormUrl: siteObj.siteSecureURL + 'returnsForm.account',

    _init: function() {
      _returnsCheck._bindEvents();
      _returnsCheck._bindOrderNumber();
      _returnsCheck._resetCustomerConfirmCheckbox();
    },

    _bindEvents: function() {
      app.element.on('change', _returnsCheck._checkSelectedCategory,
        _returnsCheck._categoryOptions);

      app.element.on('change', _returnsCheck._checkSelectedReturnReason,
        _returnsCheck._returnOptions);

      app.element.on('click', _returnsCheck._productCheckboxClick,
        _returnsCheck._productCheckbox);

      app.element.on('click', _returnsCheck._customerConfirmationCheckboxClick,
        _returnsCheck._customerConfirmationCheckbox);

      app.element.on('click', _returnsCheck._checkCustomerConfirmation,
        _returnsCheck._returnsFormButton);
    },

    _bindOrderNumber: function() {
      _returnsCheck._data.orderNumber = app.element.getAttribute('data-order-number',
        _returnsCheck._returnsCheck);
    },

    _resetCustomerConfirmCheckbox: function() {
      if (_returnsCheck._customerConfirmationCheckbox) {
        _returnsCheck._customerConfirmationCheckbox.checked = false;
      }
    },

    _bindProductIds: function() {
      var products = document.querySelectorAll('.js-returns-check-checkbox-checked');

      if (products) {
        _returnsCheck._data.productIds = [];
        app.element.forEach(function(product) {
          _returnsCheck._data.productIds.push(
            parseInt(app.element.getAttribute('data-product-id', product), 10)
          );
        }, products);
      }
    },

    _startLoading: function() {
      if (_returnsCheck._loading) {
        app.element.addClass(_returnsCheck._cssClasses.showLoading, _returnsCheck._loading);
      }
    },

    _stopLoading: function() {
      if (_returnsCheck._loading) {
        app.element.removeClass(_returnsCheck._cssClasses.showLoading, _returnsCheck._loading);
      }
    },

    _selectProduct: function(productId) {
      var selectProductInput = document.querySelector('[data-select-product="' + productId + '"]');
      if (selectProductInput) {
        selectProductInput.value = true;
      }
    },

    _deselectProduct: function(productId) {
      var selectProductInput = document.querySelector('[data-select-product="' + productId + '"]');

      if (selectProductInput) {
        selectProductInput.value = false;
      }
    },

    _productCheckboxClick: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;

      if (!target) {
        return;
      }

      var productId = app.element.getAttribute('data-product-id', target);

      if (app.element.hasClass(_returnsCheck._cssClasses.checkedProductCheckbox, target)) {
        app.element.removeClass(_returnsCheck._cssClasses.checkedProductCheckbox, target);
        _returnsCheck._deselectProduct(productId);
      } else {
        app.element.addClass(_returnsCheck._cssClasses.checkedProductCheckbox, target);
        _returnsCheck._selectProduct(productId);
      }

      if (_returnsCheck._checkErrorIsVisible) {
        _returnsCheck._hideCheckErrorMessage();
      }

      _returnsCheck._bindProductIds();
      _returnsCheck._resetReturnReasons();
      _returnsCheck._hideReturnFormButton();
    },

    _customerConfirmationCheckboxClick: function() {
      if (_returnsCheck._formErrorIsVisible) {
        _returnsCheck._hideFormErrorMessage();
      }
    },

    _showCheckErrorMessage: function() {
      var checkErrorMessage = document.querySelector(_returnsCheck._elements.checkErrorMessage);

      if (checkErrorMessage) {
        _returnsCheck._checkErrorIsVisible = true;
        app.element.addClass(_returnsCheck._cssClasses.showCheckError, checkErrorMessage);
      }
    },

    _hideCheckErrorMessage: function() {
      var checkErrorMessage = document.querySelector(_returnsCheck._elements.checkErrorMessage);

      if (checkErrorMessage && app.element.hasClass(_returnsCheck._cssClasses.showCheckError, checkErrorMessage)) {
        _returnsCheck._checkErrorIsVisible = false;
        app.element.removeClass(_returnsCheck._cssClasses.showCheckError, checkErrorMessage);
      }
    },

    _showFormErrorMessage: function() {
      var formErrorMessage = document.querySelector(_returnsCheck._elements.formErrorMessage);

      if (formErrorMessage) {
        _returnsCheck._formErrorIsVisible = true;
        app.element.addClass(_returnsCheck._cssClasses.showFormError, formErrorMessage);
      }
    },

    _hideFormErrorMessage: function() {
      var formErrorMessage = document.querySelector(_returnsCheck._elements.formErrorMessage);

      if (formErrorMessage) {
        if (app.element.hasClass(_returnsCheck._cssClasses.showFormError, formErrorMessage)) {
          _returnsCheck._formErrorIsVisible = false;
          app.element.removeClass(_returnsCheck._cssClasses.showFormError, formErrorMessage);
        }
      }
    },

    _checkSelectedCategory: function() {
      var returnsCheckOptions = document.querySelector('.js-returns-check-categories');

      if (returnsCheckOptions) {
        var categoryId = _returnsCheck._categoryOptions.value;
        if (parseInt(categoryId, 10) === _returnsCheck._data.returnsAndRefundsId) {
          _returnsCheck._showReturnReasons();
        } else {
          _returnsCheck._hideReturnReasons();
        }
      }
    },

    _showReturnReasons: function() {
      var returnsCheckOptionsBlock = _returnsCheck._returnsCheckOptionsBlock;

      if (returnsCheckOptionsBlock) {
        app.element.addClass(_returnsCheck._cssClasses.showReturnReasons, returnsCheckOptionsBlock);
        _returnsCheck._bindEvents();
      }
    },

    _hideReturnReasons: function() {
      var returnsCheckOptionsBlock = _returnsCheck._returnsCheckOptionsBlock;

      if (returnsCheckOptionsBlock) {
        app.element.removeClass(_returnsCheck._cssClasses.showReturnReasons,
          returnsCheckOptionsBlock);
        _returnsCheck._bindEvents();
      }
    },

    _resetReturnReasons: function() {
      var returnOptions = document.querySelector(_returnsCheck._elements.returnOptions);

      if (returnOptions) {
        returnOptions.value = 0;
      }
    },

    _checkSelectedReturnReason: function() {
      var returnOptions = document.querySelector(_returnsCheck._elements.returnOptions);

      if (returnOptions) {
        var reasonId = returnOptions.value;
        if (parseInt(reasonId, 10) === _returnsCheck._data.itemNoLongerRequiredId) {

          if (_returnsCheck._data.productIds.length === 0) {
            _returnsCheck._showCheckErrorMessage();
          } else {
            _returnsCheck._checkReturnEligibility();
            _returnsCheck._bindEvents();
          }

        } else {
          _returnsCheck._hideReturnFormButton();
        }
      }
    },

    _checkReturnEligibility: function() {
      _returnsCheck._startLoading();

      if (_returnsCheck._data.orderNumber !== null && _returnsCheck._data.productIds.length > 0) {
        app.ajax.post({
          url: _returnsCheck._returnsCheckUrl,
          send: {
            orderNumber: _returnsCheck._data.orderNumber,
            productIds: _returnsCheck._data.productIds
          },
          requestHeader: {
            header: 'Content-Type',
            value: 'application/json'
          },
          error: function() {
            _returnsCheck._stopLoading();
          },
          success: function(response) {
            var returnsResponse = JSON.parse(response);
            if (returnsResponse.returnable) {
              _returnsCheck._showReturnFormButton();
              _returnsCheck._stopLoading();
            } else {
              _returnsCheck._stopLoading();
            }
          }
        });
      }
    },

    _showReturnFormButton: function() {
      var returnButton = document.querySelector(_returnsCheck._elements.returnsCheckButton);

      if (returnButton) {
        app.element.addClass(_returnsCheck._cssClasses.showButton, returnButton);

        var returnFormButton = document.querySelector(_returnsCheck._elements.returnsFormButton);
        if (returnFormButton) {
          app.element.on('click', _returnsCheck._checkCustomerConfirmation,
            returnFormButton);
        }
      }
    },

    _hideReturnFormButton: function() {
      var returnButton = document.querySelector(_returnsCheck._elements.returnsCheckButton);

      if (returnButton) {
        app.element.removeClass(_returnsCheck._cssClasses.showButton, returnButton);
      }
    },

    _checkCustomerConfirmation: function() {
      var customerConfirmation = document.querySelector(_returnsCheck._elements.customerConfirmCheckbox);
      if (customerConfirmation) {
        if (customerConfirmation.checked) {
          _returnsCheck._submitAndDownload();
        } else {
          _returnsCheck._showFormErrorMessage();
        }
      }
    },

    _submitAndDownload: function() {
      _returnsCheck._returnsFormForm.submit();
    },
  };

  // Execute module constructor
  if (document.querySelector('[data-component="myAccountReturnsCheck"]')) _returnsCheck._init();

  // Unit testing access
  return _returnsCheck;
});
